/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("typeface-montserrat")
require("typeface-roboto-slab")

import { detectIE } from "./src/components/Toast/comIEDetect"

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const { pathname } = location
  const { hashname } = window.location.hash
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/`,
    `/404`,
    "patient-stories",
    "wisdom-teeth-removal"
  ]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (!location.hash) {
    window.scrollTo(0, 0)
  } else {
    const currentPosition = getSavedScrollPosition(location)
    const queriedPosition = getSavedScrollPosition({
      pathname: `/patient-stories`
    })
    window.scrollTo(...(currentPosition || [0, 0]))
  }

  return false
}

const onRouteUpdate = ({ location }) => scrollToAnchor(location)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 88) {
  // Check for location so build does not fail
  if (location && location.hash) {
    if (document.querySelector(`${location.hash}`)) {
      const item = document.querySelector(`${location.hash}`).offsetTop
      window.scrollTo({
        top: item - mainNavHeight,
        behavior: "smooth"
      })
    }
  }

  return true
}

const onClientEntry = () => {
  var browserName = ""
  var browserStatus = true
  //  console.log("We've started onClientEntry!");

  var version = detectIE()
  //  console.log("Version:" + version);

  if (version === false) {
    // is normal browser
    //this.addToast(this.state.browserName);
    window.addEventListener("load", () => {
      // console.log(version);
      document.body.setAttribute("id", "browser-name" + "-" + "modern")
    })
    //Toast({ message: version });
  } else if (version >= 12) {
    browserName = "Edge " + version
    //this.addToast(this.state.browserName);
    //  console.log('Edge ' + version);
    //Toaster.show({ message: 'Edge ' + version });
  } else {
    browserName = "IE " + version
    window.addEventListener("load", () => {
      // console.log('IE ' + version);
      document.body.setAttribute("id", "browser-name" + "-" + "IE")
      var css = `.modal-window {
          position: fixed;
          background-color: rgba(251, 242, 227, 0.6);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 999;
          display: block;
          opacity: 1;
          pointer-events: none;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          color: #d92b2b;
        }
        .modal-window.start-hidden {
          display: none;
          opacity: 0;
        }
        
        .modal-window:target {
          display: block;
          opacity: 1;
          pointer-events: auto;
        }
        
        .modal-window header {
          font-weight: bold;
        }
        .modal-window h1 {
          font-size: 150%;
          margin: 0 0 15px;
          color: #d92b2b;
        }
        
        .modal-close {
          color: #fff;
          line-height: 50px;
          font-size: 80%;
          position: absolute;
          right: 10px;
          text-align: center;
          top: 10px;
          width: 70px;
          text-decoration: none;
          background: grey;
        }
        .modal-close:hover {
          color: black;
        }
        
        .container {
          display: grid;
          -webkit-box-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
                  align-items: center;
          height: 100vh;
        }
        
        .modal-window div:not(:last-of-type) {
          margin-bottom: 15px;
        }
        .inner-modal{
          width: 80vw;
          height: 80vh;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          padding: 60px;
          background: #fff;
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .non-comp-content {
          width: 80%;
          font-size: 26px;
          line-height: 1.54;
        }
        
        .inner-modal > *{
          padding: 10px;
        }
        
        `,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style")

      head.appendChild(style)

      style.type = "text/css"
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
      document.body.innerHTML += `<div id="open-modal" class="modal-window start-hidden">
        <div class="inner-modal">
          <a href="#" title="Close" class="modal-close"></a>
          <h1><img src="https://res.cloudinary.com/nuvolum/image/upload/h_26/v1/AOMS/DEV/exclamation-triangle-solid.png"> Warning</h1>
          <div class="non-comp-content">Uh-oh! Your browser isn’t supported and does not meet the Americans with Disabilities Act (ADA) Standards for Accessible compliance. We recommend you use Mozilla Firefox or Google Chrome for a better experience</div>
          <div class="not-comp-links">
            <a class="standard-button" href="https://www.google.com/chrome/">Chrome</a>
            <a class="standard-button" href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
          </div>
      </div>`
    })
    function jump(h) {
      var url = location.href
      location.href = "#" + h
    }

    jump("open-modal")
  }
}

export { onClientEntry, shouldUpdateScroll, onRouteUpdate }
